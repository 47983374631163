/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/* stylelint-disable selector-class-pattern */
.bp4-datepicker {
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  padding: 5px;
  position: relative;
  user-select: none;
}
.bp4-datepicker .DayPicker {
  display: inline-block;
  min-width: 210px;
  position: relative;
  vertical-align: top;
}
.bp4-datepicker .DayPicker:focus {
  outline: none;
}
.bp4-datepicker .bp4-datepicker-day-wrapper {
  border-radius: 2px;
  padding: 7px;
}
.bp4-datepicker .DayPicker-Month {
  border-collapse: collapse;
  border-spacing: 0;
  display: inline-table;
  margin: 0 5px;
  user-select: none;
}
.bp4-datepicker .DayPicker-Month + .bp4-datepicker .DayPicker-Month {
  margin-left: 10px;
}
.bp4-datepicker .DayPicker-Caption {
  display: table-caption;
}
.bp4-datepicker .DayPicker-Weekdays {
  display: table-header-group;
}
.bp4-datepicker .DayPicker-WeekdaysRow {
  display: table-row;
}
.bp4-datepicker .DayPicker-Weekday {
  display: table-cell;
  height: 30px;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  width: 30px;
  font-weight: 600;
  padding-top: 5px;
}
.bp4-datepicker .DayPicker-Weekday abbr[title] {
  text-decoration: none;
}
.bp4-datepicker .DayPicker-Body {
  display: table-row-group;
}
.bp4-datepicker .DayPicker-Week {
  display: table-row;
}
.bp4-datepicker .DayPicker-WeekNumber {
  display: table-cell;
  height: 30px;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  width: 30px;
  color: rgba(95, 107, 124, 0.6);
  font-size: 14px;
}
.bp4-datepicker .DayPicker-Day {
  display: table-cell;
  height: 30px;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  width: 30px;
  border-radius: 2px;
  cursor: pointer;
}
.bp4-datepicker .DayPicker-Day.DayPicker-Day--outside {
  color: rgba(95, 107, 124, 0.6);
}
.bp4-datepicker .DayPicker-Day.DayPicker-Day--isToday .bp4-datepicker-day-wrapper {
  border: 1px solid rgba(17, 20, 24, 0.15);
}
.bp4-datepicker .DayPicker-Day:hover, .bp4-datepicker .DayPicker-Day:focus {
  background: rgba(143, 153, 168, 0.15);
  color: #1c2127;
}
.bp4-datepicker .DayPicker-Day:active {
  background: rgba(143, 153, 168, 0.3);
}
.bp4-datepicker .DayPicker-Day.DayPicker-Day--selected {
  background-color: #2d72d2;
  border-radius: 2px;
  color: #ffffff;
}
.bp4-datepicker .DayPicker-Day.DayPicker-Day--selected:hover {
  background-color: #215db0;
}
.bp4-datepicker .DayPicker-Day.DayPicker-Day--selected:active {
  background-color: #184a90;
}
.bp4-datepicker .DayPicker-Day.DayPicker-Day--disabled {
  background: none;
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
}

.bp4-datepicker-navbar {
  align-items: center;
  display: flex;
  height: 30px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.bp4-datepicker-navbar > .DayPicker-NavButton--prev {
  margin-right: auto;
}
.bp4-datepicker-navbar > .DayPicker-NavButton--next {
  margin-left: auto;
}

.bp4-datepicker-caption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 25px 5px;
}
.bp4-datepicker-caption > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.bp4-datepicker-caption > :first-child {
  flex-grow: 1;
  flex-shrink: 1;
}
.bp4-datepicker-caption select {
  font-weight: 600;
  padding-left: 5px;
  padding-right: 16px;
}
.bp4-datepicker-caption select + .bp4-icon {
  right: 2px;
}
.bp4-datepicker-caption + .bp4-divider {
  margin: 0;
}

.bp4-datepicker-month-select {
  flex-shrink: 1;
}

.bp4-datepicker-year-select {
  flex-shrink: 1;
  min-width: 60px;
}

.bp4-datepicker-caption-measure {
  font-weight: 600;
  padding-left: 5px;
}

.bp4-datepicker-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.bp4-datepicker-content > .bp4-divider {
  margin: 0;
  width: calc(100% - 10px);
}

.bp4-datepicker-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.bp4-dark .bp4-datepicker {
  background: #2f343c;
}
.bp4-dark .bp4-datepicker .DayPicker-WeekNumber {
  color: rgba(171, 179, 191, 0.6);
}
.bp4-dark .bp4-datepicker .DayPicker-Day.DayPicker-Day--outside {
  color: rgba(171, 179, 191, 0.6);
}
.bp4-dark .bp4-datepicker .DayPicker-Day.DayPicker-Day--isToday .bp4-datepicker-day-wrapper {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.bp4-dark .bp4-datepicker .DayPicker-Day:hover, .bp4-dark .bp4-datepicker .DayPicker-Day:focus {
  background: rgba(143, 153, 168, 0.15);
  color: #ffffff;
}
.bp4-dark .bp4-datepicker .DayPicker-Day:active {
  background: rgba(143, 153, 168, 0.3);
}
.bp4-dark .bp4-datepicker .DayPicker-Day.DayPicker-Day--selected {
  background-color: #2d72d2;
}
.bp4-dark .bp4-datepicker .DayPicker-Day.DayPicker-Day--selected:hover {
  background-color: #215db0;
}
.bp4-dark .bp4-datepicker .DayPicker-Day.DayPicker-Day--selected:active {
  background-color: #184a90;
}
.bp4-dark .bp4-datepicker .DayPicker-Day.DayPicker-Day--disabled {
  background: none;
  color: rgba(171, 179, 191, 0.6);
}
.bp4-dark .bp4-datepicker .bp4-datepicker-footer {
  border-top-color: rgba(17, 20, 24, 0.4);
}

.bp4-datepicker-timepicker-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.bp4-datepicker-timepicker-wrapper .bp4-timepicker-arrow-row:empty + .bp4-timepicker-input-row {
  margin: 5px 0;
}

/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/* stylelint-disable selector-class-pattern */
.bp4-daterangepicker {
  display: flex;
}
.bp4-daterangepicker .DayPicker-NavButton--interactionDisabled {
  display: none;
}
.bp4-daterangepicker.bp4-daterangepicker-contiguous .DayPicker {
  min-width: 220px;
}
.bp4-daterangepicker.bp4-daterangepicker-single-month .DayPicker {
  min-width: 210px;
}
.bp4-daterangepicker .DayPicker-Day {
  /* stylelint-disable max-line-length */
  /* stylelint-enable max-line-length */
}
.bp4-daterangepicker .DayPicker-Day--outside {
  visibility: hidden;
}
.bp4-daterangepicker .DayPicker-Day--hovered-range {
  border-radius: 0;
  color: #215db0;
  /* stylelint-disable max-line-length */
  /* stylelint-enable max-line-length */
}
.bp4-daterangepicker .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end) {
  background-color: rgba(45, 114, 210, 0.1);
}
.bp4-daterangepicker .DayPicker-Day--selected-range {
  background-color: rgba(45, 114, 210, 0.1);
  border-radius: 0;
  color: #215db0;
}
.bp4-daterangepicker .DayPicker-Day--selected-range:hover {
  background-color: rgba(45, 114, 210, 0.2);
  color: #215db0;
}
.bp4-daterangepicker .DayPicker-Day--selected-range-start:not(.DayPicker-Day--selected-range-end):not(.DayPicker-Day--hovered-range-end) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.bp4-daterangepicker .DayPicker-Day--selected-range-end:not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--hovered-range-start) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.bp4-daterangepicker .DayPicker-Day--hovered-range-start:not(.DayPicker-Day--hovered-range-end) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.bp4-daterangepicker .DayPicker-Day--hovered-range-end:not(.DayPicker-Day--hovered-range-start) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.bp4-dark .bp4-daterangepicker .DayPicker-Day--hovered-range {
  color: #f6f7f9;
  /* stylelint-disable max-line-length */
  /* stylelint-enable max-line-length */
}
.bp4-dark .bp4-daterangepicker .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end) {
  background-color: rgba(45, 114, 210, 0.2);
}
.bp4-dark .bp4-daterangepicker .DayPicker-Day--selected-range {
  background-color: rgba(45, 114, 210, 0.2);
  color: #f6f7f9;
}
.bp4-dark .bp4-daterangepicker .DayPicker-Day--selected-range:hover {
  background-color: rgba(45, 114, 210, 0.4);
}

.bp4-daterangepicker-calendars {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.bp4-daterangepicker-timepickers {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}
.bp4-daterangepicker-timepickers .bp4-timepicker-arrow-row:empty + .bp4-timepicker-input-row {
  margin: 5px 0;
}

.bp4-menu.bp4-daterangepicker-shortcuts {
  min-width: 120px;
  padding: 0;
}

/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
.bp4-timepicker {
  white-space: nowrap;
}
.bp4-timepicker .bp4-timepicker-arrow-row {
  padding: 0 1px;
}
.bp4-timepicker .bp4-timepicker-arrow-button {
  color: #5f6b7c;
  display: inline-block;
  padding: 4px 0;
  text-align: center;
  width: 33px;
}
.bp4-timepicker .bp4-timepicker-arrow-button:hover {
  color: #1c2127;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-arrow-button {
  color: #abb3bf;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-arrow-button:hover {
  color: #f6f7f9;
}
.bp4-timepicker .bp4-timepicker-arrow-button + .bp4-timepicker-arrow-button {
  margin-left: 11px;
}
.bp4-timepicker .bp4-timepicker-arrow-button:hover {
  cursor: pointer;
}
.bp4-timepicker .bp4-timepicker-input-row {
  background: #ffffff;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
  display: inline-block;
  height: 30px;
  line-height: 28px;
  padding: 0 1px;
  vertical-align: middle;
}
.bp4-timepicker .bp4-timepicker-divider-text {
  color: #5f6b7c;
  display: inline-block;
  font-size: 16px;
  text-align: center;
  width: 11px;
}
.bp4-timepicker .bp4-timepicker-input {
  background: transparent;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0);
  color: #1c2127;
  height: 28px;
  outline: 0;
  padding: 0;
  text-align: center;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  width: 33px;
}
.bp4-timepicker .bp4-timepicker-input:focus {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px rgba(45, 114, 210, 0.3);
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-primary {
  box-shadow: 0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0), inset 0 0 0 1px #2d72d2, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-primary:focus {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px rgba(45, 114, 210, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-primary[readonly] {
  box-shadow: inset 0 0 0 1px #2d72d2;
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-primary:disabled, .bp4-timepicker .bp4-timepicker-input.bp4-intent-primary.bp4-disabled {
  box-shadow: none;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-primary {
  box-shadow: 0 0 0 0 rgba(76, 144, 240, 0), 0 0 0 0 rgba(76, 144, 240, 0), 0 0 0 0 rgba(76, 144, 240, 0), inset 0 0 0 1px #4c90f0, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-primary:focus {
  box-shadow: inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #4c90f0, 0 0 0 2px rgba(76, 144, 240, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-primary[readonly] {
  box-shadow: inset 0 0 0 1px #4c90f0;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-primary:disabled, .bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-primary.bp4-disabled {
  box-shadow: none;
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-success {
  box-shadow: 0 0 0 0 rgba(35, 133, 81, 0), 0 0 0 0 rgba(35, 133, 81, 0), inset 0 0 0 1px #238551, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-success:focus {
  box-shadow: inset 0 0 0 1px #238551, 0 0 0 2px rgba(35, 133, 81, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-success[readonly] {
  box-shadow: inset 0 0 0 1px #238551;
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-success:disabled, .bp4-timepicker .bp4-timepicker-input.bp4-intent-success.bp4-disabled {
  box-shadow: none;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-success {
  box-shadow: 0 0 0 0 rgba(50, 164, 103, 0), 0 0 0 0 rgba(50, 164, 103, 0), 0 0 0 0 rgba(50, 164, 103, 0), inset 0 0 0 1px #32a467, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-success:focus {
  box-shadow: inset 0 0 0 1px #32a467, inset 0 0 0 1px #32a467, 0 0 0 2px rgba(50, 164, 103, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-success[readonly] {
  box-shadow: inset 0 0 0 1px #32a467;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-success:disabled, .bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-success.bp4-disabled {
  box-shadow: none;
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-warning {
  box-shadow: 0 0 0 0 rgba(200, 118, 25, 0), 0 0 0 0 rgba(200, 118, 25, 0), inset 0 0 0 1px #c87619, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-warning:focus {
  box-shadow: inset 0 0 0 1px #c87619, 0 0 0 2px rgba(200, 118, 25, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-warning[readonly] {
  box-shadow: inset 0 0 0 1px #c87619;
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-warning:disabled, .bp4-timepicker .bp4-timepicker-input.bp4-intent-warning.bp4-disabled {
  box-shadow: none;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-warning {
  box-shadow: 0 0 0 0 rgba(236, 154, 60, 0), 0 0 0 0 rgba(236, 154, 60, 0), 0 0 0 0 rgba(236, 154, 60, 0), inset 0 0 0 1px #ec9a3c, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-warning:focus {
  box-shadow: inset 0 0 0 1px #ec9a3c, inset 0 0 0 1px #ec9a3c, 0 0 0 2px rgba(236, 154, 60, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-warning[readonly] {
  box-shadow: inset 0 0 0 1px #ec9a3c;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-warning:disabled, .bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-warning.bp4-disabled {
  box-shadow: none;
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-danger {
  box-shadow: 0 0 0 0 rgba(205, 66, 70, 0), 0 0 0 0 rgba(205, 66, 70, 0), inset 0 0 0 1px #cd4246, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-danger:focus {
  box-shadow: inset 0 0 0 1px #cd4246, 0 0 0 2px rgba(205, 66, 70, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-danger[readonly] {
  box-shadow: inset 0 0 0 1px #cd4246;
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-danger:disabled, .bp4-timepicker .bp4-timepicker-input.bp4-intent-danger.bp4-disabled {
  box-shadow: none;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-danger {
  box-shadow: 0 0 0 0 rgba(231, 106, 110, 0), 0 0 0 0 rgba(231, 106, 110, 0), 0 0 0 0 rgba(231, 106, 110, 0), inset 0 0 0 1px #e76a6e, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-danger:focus {
  box-shadow: inset 0 0 0 1px #e76a6e, inset 0 0 0 1px #e76a6e, 0 0 0 2px rgba(231, 106, 110, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-danger[readonly] {
  box-shadow: inset 0 0 0 1px #e76a6e;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-danger:disabled, .bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-danger.bp4-disabled {
  box-shadow: none;
}
@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .bp4-timepicker .bp4-timepicker-input {
    border: 1px solid buttonborder;
  }
}
.bp4-timepicker .bp4-timepicker-ampm-select {
  margin-left: 5px;
}
.bp4-timepicker.bp4-disabled .bp4-timepicker-input-row {
  background: rgba(211, 216, 222, 0.5);
  box-shadow: none;
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
  resize: none;
}
.bp4-timepicker.bp4-disabled .bp4-timepicker-input-row::placeholder {
  color: rgba(95, 107, 124, 0.6);
}
.bp4-timepicker.bp4-disabled .bp4-timepicker-input,
.bp4-timepicker.bp4-disabled .bp4-timepicker-divider-text {
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
}
.bp4-timepicker.bp4-disabled .bp4-timepicker-arrow-button,
.bp4-timepicker.bp4-disabled .bp4-timepicker-arrow-button:hover {
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
}

.bp4-dark .bp4-timepicker .bp4-timepicker-input-row {
  background: rgba(17, 20, 24, 0.3);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-divider-text {
  color: #abb3bf;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input {
  color: #f6f7f9;
}
.bp4-dark .bp4-timepicker.bp4-disabled .bp4-timepicker-input,
.bp4-dark .bp4-timepicker.bp4-disabled .bp4-timepicker-divider-text,
.bp4-dark .bp4-timepicker.bp4-disabled .bp4-timepicker-arrow-button,
.bp4-dark .bp4-timepicker.bp4-disabled .bp4-timepicker-arrow-button:hover {
  color: rgba(171, 179, 191, 0.6);
}

/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
.bp4-datetimepicker {
  background-color: #ffffff;
  border-radius: 2px;
  padding: 10px;
  text-align: center;
}
.bp4-dark .bp4-datetimepicker {
  background: #383e47;
}
.bp4-dark .bp4-datetimepicker .bp4-datepicker {
  border-bottom: 1px solid rgba(17, 20, 24, 0.4);
}
.bp4-datetimepicker .bp4-datepicker {
  border-bottom: 1px solid rgba(17, 20, 24, 0.15);
  padding: 0 0 10px;
}
.bp4-datetimepicker .bp4-timepicker {
  margin-top: 10px;
}

.bp4-dateinput-popover {
  padding: 0;
}